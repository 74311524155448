import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert,  Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { BcanConfig, sbConfig } from './Types'
import { SystemHeader } from './CommonUI';

import { transpileModule } from 'typescript';
import { stringify } from 'querystring';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface BcanConfigViewProps {
    gsb: AxiosInstance,
    BcanConfig: BcanConfig,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function BcanConfigView({ gsb, BcanConfig = {} as BcanConfig, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: BcanConfigViewProps) {

    const [isDeletePress, setisDeletePress] = useState(false)

     const [CHQ_NAME, setCHQ_NAME] = useState(false)
     const [SUSPEND_AC, setSUSPEND_AC] = useState(false)
     const [CM2K_USER, setCM2K_USER] = useState(false)
 
   
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                setSUSPEND_AC(GsbFlag.SUSPEND_AC)               
                setCHQ_NAME(GsbFlag.CHQ_NAME)
                setCM2K_USER( GsbFlag.CM2K_USER);
                //console.log("set StatusList: ", StatusList)
 



            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, BcanConfig ])



    if (! ("d_tr_date" in BcanConfig)) return null


    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                
            </div>
            <div className="Spacing-V-16" />
            {/*
            <div className="Row">
                <FormGroup className="Col-1-2" label="System Date">
                    <InputGroup readOnly value= {utils.dispDate(BcanConfig.d_tr_date, GsbFlag.dateFormat)} />
                </FormGroup>
            </div>
            */}
            <div className="Row">
                <FormGroup className="Col-1-2" label="HK CE Number">
                    <InputGroup readOnly value={BcanConfig.d_CE_No} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Firm Id">
                    <InputGroup readOnly value={BcanConfig.d_FirmId} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Executing CCEP Id">
                    <InputGroup readOnly value={BcanConfig.d_CCEPId} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col3-1-2" label="HK BCAN Gen Type" >
                    <InputGroup readOnly value={BcanConfig.d_Bcan_GenType} />
                    { BcanConfig.d_Bcan_GenType === "ACCNO" && <span >Account No. + Random</span> }
                    { BcanConfig.d_Bcan_GenType === "RANDOM" && <span >Random</span> }
                </FormGroup>
            </div>  

            <div className="Spacing-V-16" />          
            { CHQ_NAME && <div className="Row">
                <FormGroup className="Col3-1-2" label="GSB import Name by" >
                    {(BcanConfig.d_BCAN_importNameby == "NAME" ||  BcanConfig.d_BCAN_importNameby.trim().length === 0)
                     ?   <InputGroup readOnly value="Name" />   
                     :   <InputGroup readOnly value="Cheque Name" />                       
                    }
                  
                </FormGroup>
            </div> }
          
            <div className="Row">
                <FormGroup className="Col-1-2" label="Allow change Bcan No." labelFor="inputBCAN_d_BCAN_Allow_Change">
                    {(BcanConfig.d_BCAN_Allow_Change == "Y")
                     ?   <InputGroup readOnly id="inputBCAN_d_BCAN_Allow_Change" value="YES" />   
                     :   <InputGroup readOnly id="inputBCAN_d_BCAN_Allow_Change" value="NO" />                       
                    }         
                </FormGroup>
            </div>



            <div className="Row">   
                <FormGroup className="Col-1-2" label="Tag NO consent BCAN TO 1" labelFor="inputBCAN_MARK_SELL_TO_1">
                    {(BcanConfig.d_BCAN_MARK_SELL_TO_1 == "Y")
                     ?   <InputGroup readOnly id="inputBCAN_MARK_SELL_TO_1" value="YES" />   
                     :   <InputGroup readOnly id="inputBCAN_MARK_SELL_TO_1" value="NO" />                       
                    }         
                
                </FormGroup>    
            </div>  



            <div className="Row">   
                <FormGroup className="Col-1-2" label="update ID number from SB" labelFor="inputBCAN_TO_ID">
                    {(BcanConfig.d_BCAN_TO_ID == "Y")
                     ?   <InputGroup readOnly id="inputBCAN_TO_ID" value="YES" />   
                     :   <InputGroup readOnly id="inputBCAN_TO_ID" value="NO" />                       
                    }         
                
                </FormGroup>    
            </div>  


            <div className="Row">   
                <FormGroup className="Col-1-2" label="Always export non natural person" labelFor="inputBCAN_EXPORT_NON_NATURAL_PERSON">
                    {(BcanConfig.d_BCAN_EXPORT_NON_NATURAL_PERSON == "Y")
                     ?   <InputGroup readOnly id="inputBCAN_EXPORT_NON_NATURAL_PERSON" value="YES" />   
                     :   <InputGroup readOnly id="inputBCAN_EXPORT_NON_NATURAL_PERSON" value="NO" />                       
                    }         
                
                </FormGroup>    
            </div>  


            { SUSPEND_AC && <div className="Row">     
                <FormGroup className="Col-1-3" label="Skip show AC STATUS in HKIDR Master" labelFor="inputBCAN_Skip_Show_AC_Status">
                        <InputGroup readOnly id="inputBCAN_Skip_Show_AC_Status" name="d_BCAN_Skip_Show_AC_Status" value={(BcanConfig.d_BCAN_Skip_Show_AC_Status)} />
                </FormGroup>
            </div>  }

            { SUSPEND_AC && <div className="Row">     
                <FormGroup className="Col-1-3" label="Other Active AC STATUS in HKIDR Master" labelFor="inputBCAN_OTHER_ACTIVE">
                        <InputGroup readOnly id="inputBCAN_OTHER_ACTIVE" name="d_BCAN_OTHER_ACTIVE" value={(BcanConfig.d_BCAN_OTHER_ACTIVE)} />
                </FormGroup>
            </div>  }


            <div className="Row">
                <FormGroup className="Col-1-3" label="Remove special Character when import GSB" labelFor="inputBCAN_Remove_Speical_Char">
                        <InputGroup readOnly id="inputBCAN_Remove_Speical_Char" name="d_BCAN_Remove_Speical_Char" value={(BcanConfig.d_BCAN_Remove_Speical_Char)} />
                </FormGroup>
            </div>                
            { SUSPEND_AC && <div className="Row">     
                <FormGroup className="Col-1-2" label="Exclusive AC Status Import" labelFor="inputBCAN_Exclusive_AC_Status_Import">
                        <InputGroup readOnly id="inputBCAN_Exclusive_AC_Status_Import" name="d_BCAN_Exclusive_AC_Status_Import" value={(BcanConfig.d_BCAN_Exclusive_AC_Status_Import)} />
                </FormGroup>
            </div> }               
            <H5 >For HK RANDOM Gen BCAN No.</H5>
            <div className="Row">
                <FormGroup className="Col-1-2" label="BCAN No Next No" labelFor="inputBcan_NextNo">
                    <InputGroup readOnly id="inputBcan_NextNo" name="d_Bcan_NextNo" value={utils.formatNumber2(BcanConfig.d_Bcan_NextNo)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="BCAN No Range Min" labelFor="inputBcan_Gen_Range1">
                    <InputGroup readOnly id="inputBcan_Gen_Range1" name="d_Bcan_Gen_Range1" value={utils.formatNumber2(BcanConfig.d_Bcan_Gen_Range1)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="BCAN No Range Max" labelFor="inputBcan_Gen_Range2">
                    <InputGroup readOnly id="inputBcan_Gen_Range2" name="d_Bcan_Gen_Range2" value={utils.formatNumber2(BcanConfig.d_Bcan_Gen_Range2)} />
                </FormGroup>
            </div>

            {CM2K_USER &&            
                <div className="Row">
                    <H5 >For FINI</H5>
                    <FormGroup className="Col-1-2" label="SEHK Participant ID for receiving the brokerage">
                        <InputGroup readOnly id="inputSEHKID" name="d_SEHK_Participant_ID"  value ={(BcanConfig.d_SEHK_Participant_ID)} /> 
                    </FormGroup>

                    <FormGroup className="Col-1-2" label="ALL Broker to Client Assigned Number">
                        {(BcanConfig.d_FINI_ALL_Borker_to_Client_Assigned_Number == "Y"  )
                        ?   <InputGroup readOnly value="YES" />   
                        :   <InputGroup readOnly value="NO" />                       
                        }                    
                        {/*<InputGroup readOnly id="inputFINI_ALL_Borker_to_Client_Assigned_Number" name="d_FINI_ALL_Borker_to_Client_Assigned_Number"  value ={(BcanConfig.d_FINI_ALL_Borker_to_Client_Assigned_Number)} /> */}
                    </FormGroup>
                </div>

                
            }



            <H5 >For OTCR Reporting</H5>
            <div className="Row">   
               
                <FormGroup className="Col-1-2" label="OTCR Reporting File Set date" >            
                    {(BcanConfig.d_OTCR_SetDay == "TODAY"  )
                     ?   <InputGroup readOnly value="TODAY" />   
                     :   <InputGroup readOnly value="TRANS_DATE" />                       
                    }
                  
                </FormGroup>
                
              
                <FormGroup className="Col-1-2" label="OTCR Reporting File Type" >            
                    {(BcanConfig.d_OTCR_FileType == "TXT"  )
                     ?   <InputGroup readOnly value="TXT" />   
                     :   <InputGroup readOnly value="ZIP" />                       
                    }
                  
                </FormGroup>
       
                <FormGroup className="Col-1-2" label="Password for Protecting the OTCR Reporting File">
                        <InputGroup readOnly id="inputOTCR_ZipPwd" name="d_OTCR_ZipPwd"  value ={(BcanConfig.d_OTCR_ZipPwd)} /> 
                </FormGroup>
               

            </div>            

            <H5 >For Import Client information file GSB/HKIDR </H5>
            <div className="Row">                  
                <FormGroup className="Col-1-3" label="Password for Protecting File">
                        <InputGroup readOnly id="inputImp_allZipPwd" name="d_imp_All_ZipPwd"  value ={(BcanConfig.d_imp_All_ZipPwd)} /> 
                </FormGroup>
            </div>   
            {/*
            <div className="Spacing-V-16" />
            <H5 >For CNBCAN</H5>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="CN BCAN Gen Type" >
                    <InputGroup readOnly value={BcanConfig.d_CNBcan_GenType} />
                    { BcanConfig.d_CNBcan_GenType === "NOT" && <span >Not Gen</span> }
                    { BcanConfig.d_CNBcan_GenType === "SAMEHK" && <span >Same as HK BCAN</span> }
                    { BcanConfig.d_CNBcan_GenType === "RANDOM" && <span >Random</span> }
                </FormGroup>
                <FormGroup className="Col-1-2" label="CN BCAN No Next No" labelFor="inputCNBcan_NextNo">
                    <InputGroup readOnly id="inputCNBcan_NextNo" name="d_CNBcan_NextNo" value={utils.formatNumber2(BcanConfig.d_CNBcan_NextNo)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="CN BCAN No Range Min" labelFor="inputCNBcan_Gen_Range1">
                    <InputGroup readOnly id="inputCNBcan_Gen_Range1" name="d_CNBcan_Gen_Range1" value={utils.formatNumber2(BcanConfig.d_CNBcan_Gen_Range1)} />
                </FormGroup>
                <FormGroup className="Col-5-2" label="CN BCAN No Range Max" labelFor="inputCNBcan_Gen_Range2">
                    <InputGroup readOnly id="inputCNBcan_Gen_Range2" name="d_CNBcan_Gen_Range2" value={utils.formatNumber2(BcanConfig.d_CNBcan_Gen_Range2)} />
                </FormGroup>
            </div>          
            */}
            
           
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Theme" >
                    <InputGroup readOnly value={BcanConfig.d_theme1} />
                    { BcanConfig.d_theme1 === "0" && <div id="DispText" >Basic </div> }
                    { BcanConfig.d_theme1 === "8" && <div id="DispText" >Dark </div> }
                    { BcanConfig.d_theme1 === "1" && <div id="DispText" >Theme 1 </div> }
                    { BcanConfig.d_theme1 === "2" && <div id="DispText" >Theme 2 </div> }
                    { BcanConfig.d_theme1 === "3" && <div id="DispText" >Theme 3 </div> }
                    { BcanConfig.d_theme1 === "4" && <div id="DispText" >Theme 4 </div> }
                    { BcanConfig.d_theme1 === "5" && <div id="DispText" >Theme 5 </div> }
                    { BcanConfig.d_theme1 === "6" && <div id="DispText" >Theme 6 </div> }
                    { BcanConfig.d_theme1 === "7" && <div id="DispText" >Theme 7 </div> }
                </FormGroup>
             </div>     
             {/*
             <div className="Row">
                <FormGroup className="Col-1-2" label="Enter to next input field" labelFor="inputEnter2Tab">
                    <RadioGroup
                        name="d_Enter2Tab"
                        disabled={true}
                        inline={true}
                        selectedValue={BcanConfig.d_Enter2Tab}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            */}
        </div>
    )
}

interface BcanConfigEditProps {
    gsb: AxiosInstance,
    BcanConfig?: BcanConfig,
    isNew?: boolean,
    onSave?: (BcanConfig: BcanConfig) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function BcanConfigEdit({ gsb, BcanConfig = {} as BcanConfig, isNew = false, onSave = () => { }, onCancel = () => { } }: BcanConfigEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<BcanConfig>()
  
    const [def_Bcan_GenType, setdef_Bcan_GenType] = useState(BcanConfig.d_Bcan_GenType)
    const [def_CNBcan_GenType, setdef_CNBcan_GenType] = useState(BcanConfig.d_CNBcan_GenType)
    const [def_d_theme1, setdef_d_theme1] = useState(BcanConfig.d_theme1)
    const [def_Enter2Tab, setdef_Enter2Tab] = useState(BcanConfig.d_Enter2Tab)

  
    const [def_Bcan_importNameby, setdef_Bcan_importNameby] = useState(BcanConfig.d_BCAN_importNameby)
    const [def_OTCR_FileType, setdef_OTCR_FileType] = useState(BcanConfig.d_OTCR_FileType) 
    const [def_d_OTCR_SetDay, setdef_OTCR_SetDay] = useState(BcanConfig.d_OTCR_SetDay) 
        
    const [def_OTCRZ_ZipPwd, setdef_OTCR_ZipPwd] = useState(BcanConfig.d_OTCR_ZipPwd)

    const [def_imp_All_ZipPwd, setdef_imp_All_ZipPwd] = useState(BcanConfig.d_imp_All_ZipPwd)
//d_FINI_ALL_Borker_to_Client_Assigned_Number
    const [def_FINI_ALL_Borker_to_Client_Assigned_Number, setdef_FINI_ALL_Borker_to_Client_Assigned_Number] = useState(BcanConfig.d_FINI_ALL_Borker_to_Client_Assigned_Number)    

    const [def_Bcan_Allow_Change, setdef_Bcan_Allow_Change] = useState(BcanConfig.d_BCAN_Allow_Change)
    const [def_BCAN_MARK_SELL_TO_1, setdef_BCAN_MARK_SELL_TO_1] = useState(BcanConfig.d_BCAN_MARK_SELL_TO_1)

    const [def_BCAN_TO_ID, setdef_BCAN_BCAN_TO_ID] = useState(BcanConfig.d_BCAN_TO_ID)

    const [def_BCAN_EXPORT_NON_NATURAL_PERSON, setdef_BCAN_BCAN_EXPORT_NON_NATURAL_PERSON] = useState(BcanConfig.d_BCAN_EXPORT_NON_NATURAL_PERSON)
    const [CHQ_NAME, setCHQ_NAME] = useState(false)    
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)  
    const [CM2K_USER, setCM2K_USER] = useState(false)
    useEffect(() => {
        // Call reset to set default values if ac is passed

       

        
        if ("d_tr_date" in BcanConfig) {
            reset(BcanConfig)
        }
    }, [BcanConfig, reset])

    
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                setSUSPEND_AC(GsbFlag.SUSPEND_AC) ;              
                setCHQ_NAME(GsbFlag.CHQ_NAME);
                //console.log("set StatusList: ", StatusList)
                setCM2K_USER( GsbFlag.CM2K_USER);

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, BcanConfig])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "d_status" })
    }, [register])

    
    useEffect(() => {

         if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    if (! isNew && !("d_tr_date" in BcanConfig)) return null


    const onSubmit = (data: BcanConfig) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_CE_No = data.d_CE_No.toUpperCase();
        data.d_Bcan_GenType = data.d_Bcan_GenType.toUpperCase();
       // data.d_CNBcan_GenType = data.d_CNBcan_GenType.toUpperCase();
        
        data.d_Enter2Tab = def_Enter2Tab

        ;

        (async () => {
            try {
                //console.log("call BcanConfigedit data", data)
                //console.log("call BcanConfigedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "BcanConfig", cAction: "BcanConfigValidate", isNew: isNew})
                //console.log("BcanConfigedit res.data.ok", res.data.ok)
                //console.log("BcanConfigedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

 


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            {/*
            <div className="Row">
                <FormGroup className="Col-1-2" label="System Date">
                    <InputGroup disabled= {true} value= {utils.dispDate(BcanConfig.d_tr_date, GsbFlag.dateFormat)} />
                </FormGroup>
            </div>
            */}
            <div className="Row">
                <FormGroup className="Col-1-2" label="HK CE Number">
                <InputGroup id="inputCE_No" name="d_CE_No" autoFocus={true}  defaultValue="" placeholder="CE No ..." inputRef={ register({ maxLength: 6}) } />
                    {errors.d_CE_No && errors.d_CE_No.types && <span id="Err_msg">{errors.d_CE_No.types.message}</span>}
                    {errors.d_CE_No && errors.d_CE_No.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Firm Id">
                <InputGroup id="inputFirmId" name="d_FirmId" defaultValue="" placeholder="Firm Id ..." inputRef={ register({ maxLength: 5}) } />
                    {errors.d_FirmId && errors.d_FirmId.types && <span id="Err_msg">{errors.d_FirmId.types.message}</span>}
                    {errors.d_FirmId && errors.d_FirmId.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Executing CCEP Id">
                <InputGroup id="inputCCEPId" name="d_CCEPId" defaultValue="" inputRef={ register({ maxLength: 5}) } />
                    {errors.d_CCEPId && errors.d_CCEPId.types && <span id="Err_msg">{errors.d_CCEPId.types.message}</span>}
                    {errors.d_CCEPId && errors.d_CCEPId.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col3-1-2" label="HK BCAN Gen Type" >
                    <select  id="inputBcan_GenType" name="d_Bcan_GenType" value={def_Bcan_GenType} onChange={utils.handleStringChange(s => setdef_Bcan_GenType(s)) }ref={register}>
                        <option value="ACCNO"> Account No. + Random </option>
                        <option value="RANDOM"> Random </option>
                    </select>
                    {errors.d_Bcan_GenType && errors.d_Bcan_GenType.types && <span id="Err_msg">{errors.d_Bcan_GenType.types.message}</span>}
                </FormGroup>
            </div>

            {/* only show in cheque module client ? */}

                  {/* 

                        {(Bcan.d_ACStatus === "A"  || Bcan.d_ACStatus === ""  || Bcan.d_ACStatus === " " )
                            ? <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>Active</td>      
                            : <td className="TCol-ACCode" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_ACStatus}</td>  
                        }*/}
                        
          
            <div className="Spacing-V-16"/>     
            { CHQ_NAME && <div className="Row">
                <FormGroup className="Col3-1-2" label="GSB import Name by" >

                     
                     <select  id="inputBcan_importby" name="d_BCAN_importNameby" value={def_Bcan_importNameby} onChange={utils.handleStringChange(s => setdef_Bcan_importNameby(s)) }ref={register}>
                            <option value="NAME">Name</option>                        
                            <option value="CHQNAME">Cheque Name</option>
                        </select> 
                

                    {errors.d_BCAN_importNameby && errors.d_BCAN_importNameby.types && <span id="Err_msg">{errors.d_BCAN_importNameby.types.message}</span>}
                </FormGroup>
            </div> }

         
            <div className="Row">   
                <FormGroup className="Col-1-2" label="Allow change HKBCAN No." labelFor="inputBCAN_BCAN_Allow_Change">
                    <select id="inputBCAN_BCAN_Allow_Change" name="d_BCAN_Allow_Change"value={def_Bcan_Allow_Change} onChange={utils.handleStringChange(s => setdef_Bcan_Allow_Change(s)) }ref={register}>
                        <option value="Y">YES</option>                        
                        <option value="N">NO</option>
                    </select>
                </FormGroup>    
            </div>  
         
            <div className="Row">   
                <FormGroup className="Col-1-2" label="Tag NO consent BCAN TO 1" labelFor="inputBCAN_MARK_SELL_TO_1">
                    <select id="inputBCAN_MARK_SELL_TO_1" name="d_BCAN_MARK_SELL_TO_1"value={def_BCAN_MARK_SELL_TO_1} onChange={utils.handleStringChange(s => setdef_BCAN_MARK_SELL_TO_1(s)) }ref={register}>
                        <option value="Y">YES</option>                        
                        <option value="N">NO</option>
                    </select>
                </FormGroup>    
            </div>  

            <div className="Row">   
                <FormGroup className="Col-1-2" label="update ID number from SB" labelFor="inputBCAN_TO_ID">
                    <select id="inputBCAN_TO_ID" name="d_BCAN_TO_ID"value={def_BCAN_TO_ID} onChange={utils.handleStringChange(s => setdef_BCAN_BCAN_TO_ID(s)) }ref={register}>
                        <option value="Y">YES</option>                        
                        <option value="N">NO</option>
                    </select>
                </FormGroup>    
            </div>  

            <div className="Row">   
                <FormGroup className="Col-1-2" label="Always export non natural person" labelFor="inputBCAN_EXPORT_NON_NATURAL_PERSON">
                    <select id="inputBCAN_EXPORT_NON_NATURAL_PERSON" name="d_BCAN_EXPORT_NON_NATURAL_PERSON"value={def_BCAN_EXPORT_NON_NATURAL_PERSON} onChange={utils.handleStringChange(s => setdef_BCAN_BCAN_EXPORT_NON_NATURAL_PERSON(s)) }ref={register}>
                        <option value="Y">YES</option>                        
                        <option value="N">NO</option>
                    </select>
                </FormGroup>    
            </div>  




            { SUSPEND_AC && <div className="Row">    
                  
                  <FormGroup className="Col-1-3" label="Skip show AC STATUS in HKIDR Master" labelFor="inputBCAN_Skip_Show_AC_Status">
                  <InputGroup id="inputBCAN_SKIPATYPE" name="d_BCAN_Skip_Show_AC_Status" defaultValue="" placeholder="Skip AC status in Master..." inputRef={ register({ maxLength: 5}) } />
                      {errors.d_BCAN_Skip_Show_AC_Status && errors.d_BCAN_Skip_Show_AC_Status.types && <span id="Err_msg">{errors.d_BCAN_Skip_Show_AC_Status.types.message}</span>}
                      {errors.d_BCAN_Skip_Show_AC_Status && errors.d_BCAN_Skip_Show_AC_Status.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                  </FormGroup>    
              </div> }
            { SUSPEND_AC && <div className="Row">    
                
                <FormGroup className="Col-1-3" label="Other Active AC STATUS in HKIDR Master" labelFor="inputBCAN_OTHER_ACTIVE">
                <InputGroup id="inputBCAN_OTHER_ACTIVE" name="d_BCAN_OTHER_ACTIVE" defaultValue="" placeholder="Other Active AC status in Master..." inputRef={ register({ maxLength: 5}) } />
                    {errors.d_BCAN_OTHER_ACTIVE && errors.d_BCAN_OTHER_ACTIVE.types && <span id="Err_msg">{errors.d_BCAN_OTHER_ACTIVE.types.message}</span>}
                    {errors.d_BCAN_OTHER_ACTIVE && errors.d_BCAN_OTHER_ACTIVE.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                </FormGroup>    
            </div> }

            <div className="Row">    
                  
                <FormGroup className="Col-1-3" label="Remove special Character when import GSB" labelFor="inputBCAN_Remove_Speical_Char">
                <InputGroup id="inputBCAN_Remove_Speical_Char" name="d_BCAN_Remove_Speical_Char" defaultValue="" placeholder="special char for remove ..." inputRef={ register({ maxLength: 5}) } />
                    {errors.d_BCAN_Remove_Speical_Char && errors.d_BCAN_Remove_Speical_Char.types && <span id="Err_msg">{errors.d_BCAN_Remove_Speical_Char.types.message}</span>}
                    {errors.d_BCAN_Remove_Speical_Char && errors.d_BCAN_Remove_Speical_Char.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                </FormGroup>    
            </div>  
            
            { SUSPEND_AC && <div className="Row">                      
                <FormGroup className="Col-1-2" label="Exclusive AC Status Import" labelFor="inputBCAN_Exclusive_AC_Status_Import">
                <InputGroup id="inputBCAN_Exclusive_AC_Status_Import" name="d_BCAN_Exclusive_AC_Status_Import" defaultValue="" placeholder="exclusive AC status Import..." inputRef={ register({ maxLength: 5}) } />
                    {errors.d_BCAN_Exclusive_AC_Status_Import && errors.d_BCAN_Exclusive_AC_Status_Import.types && <span id="Err_msg">{errors.d_BCAN_Exclusive_AC_Status_Import.types.message}</span>}
                    {errors.d_BCAN_Exclusive_AC_Status_Import && errors.d_BCAN_Exclusive_AC_Status_Import.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                </FormGroup>    
            </div> }

            <H5 >For HK RANDOM Gen BCAN No.</H5>
            <div className="Row">
                <FormGroup className="Col-1-2" label="BCAN No Next No" labelFor="inputBcan_NextNo">
                    <InputGroup id="inputBcan_NextNo" name="d_Bcan_NextNo" defaultValue="101"   inputRef={register({min: 100, max: 9999999999})} />
                    {errors.d_Bcan_NextNo && errors.d_Bcan_NextNo.type === "min" && <span id="Err_msg">BCAN No Next No sholud be greater than 100 </span>}
                    {errors.d_Bcan_NextNo && errors.d_Bcan_NextNo.type === "max" && <span id="Err_msg">BCAN No Next No sholud be less than 9999999999 </span>}
                    {errors.d_Bcan_NextNo && errors.d_Bcan_NextNo.types && <span id="Err_msg">{errors.d_Bcan_NextNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="BCAN No Range Min" labelFor="inputBcan_Gen_Range1">
                    <InputGroup id="inputBcan_Gen_Range1" name="d_Bcan_Gen_Range1" defaultValue="101"   inputRef={register({min: 0, max: 9999999999})} />
                    {errors.d_Bcan_Gen_Range1 && errors.d_Bcan_Gen_Range1.type === "min" && <span id="Err_msg">BCAN No Range Min sholud be greater than 0 </span>}
                    {errors.d_Bcan_Gen_Range1 && errors.d_Bcan_Gen_Range1.type === "max" && <span id="Err_msg">BCAN No Range Min sholud be less than 9999999999 </span>}
                    {errors.d_Bcan_Gen_Range1 && errors.d_Bcan_Gen_Range1.types && <span id="Err_msg">{errors.d_Bcan_Gen_Range1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="BCAN No Range Max" labelFor="inputBcan_Gen_Range2">
                    <InputGroup id="inputBcan_Gen_Range2" name="d_Bcan_Gen_Range2" defaultValue="101"   inputRef={register({min: 0, max: 9999999999})} />
                    {errors.d_Bcan_Gen_Range2 && errors.d_Bcan_Gen_Range2.type === "min" && <span id="Err_msg">BCAN No Range Max sholud be greater than 0 </span>}
                    {errors.d_Bcan_Gen_Range2 && errors.d_Bcan_Gen_Range2.type === "max" && <span id="Err_msg">BCAN No Range Max sholud be less than 9999999999 </span>}
                    {errors.d_Bcan_Gen_Range2 && errors.d_Bcan_Gen_Range2.types && <span id="Err_msg">{errors.d_Bcan_Gen_Range2.types.message}</span>}
                </FormGroup>
            </div>
                      
            {CM2K_USER &&
                <div className="Row">
                    <H5 >For FINI</H5>
                    <FormGroup className="Col-1-2" label="SEHK Participant ID for receiving the brokerage">
                    <InputGroup id="inputSEHKID" name="d_SEHK_Participant_ID" defaultValue="" placeholder="SEHK Participant ID ..." inputRef={ register({ maxLength: 5}) } />
                        {errors.d_SEHK_Participant_ID && errors.d_SEHK_Participant_ID.types && <span id="Err_msg">{errors.d_SEHK_Participant_ID.types.message}</span>}
                        {errors.d_SEHK_Participant_ID && errors.d_SEHK_Participant_ID.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                    </FormGroup>
                </div>
            }

                    <FormGroup className="Col-1-2" label="ALL Broker to Client Assigned Number">
                
                        <select  id="inputFINI_ALL_Borker_to_Client_Assigned_Number" name="d_FINI_ALL_Borker_to_Client_Assigned_Number" value={def_FINI_ALL_Borker_to_Client_Assigned_Number} onChange={utils.handleStringChange(s => setdef_FINI_ALL_Borker_to_Client_Assigned_Number(s)) }ref={register}>
                                <option value="Y">YES</option>                        
                                <option value="N">NO</option>
                            </select> 
                        {errors.d_FINI_ALL_Borker_to_Client_Assigned_Number && errors.d_FINI_ALL_Borker_to_Client_Assigned_Number.types && <span id="Err_msg">{errors.d_FINI_ALL_Borker_to_Client_Assigned_Number.types.message}</span>}
                    </FormGroup>


                  
            <H5 >For OTCR Reporting</H5>
            <div className="Spacing-V-16"/>     
                <div className="Row">                    

                    <FormGroup className="Col3-1-2" label="OTCR Reporting Set Date" >

                        <select  id="inputOTCR_SetDay" name="d_OTCR_SetDay" value={def_d_OTCR_SetDay} onChange={utils.handleStringChange(s => setdef_OTCR_SetDay(s)) }ref={register}>
                            <option value="TODAY">TODAY</option>                        
                            <option value="TRANS_DATE">TRANS_DATE</option>
                        </select> 
                        {errors.d_OTCR_FileType && errors.d_OTCR_FileType.types && <span id="Err_msg">{errors.d_OTCR_FileType.types.message}</span>}
                    </FormGroup> 
                    <FormGroup className="Col3-1-2" label="OTCR Reporting File Type" >

                        <select  id="inputOTCR_FileType" name="d_OTCR_FileType" value={def_OTCR_FileType} onChange={utils.handleStringChange(s => setdef_OTCR_FileType(s)) }ref={register}>
                                <option value="TXT">TXT</option>                        
                                <option value="ZIP">ZIP</option>
                            </select> 
                        {errors.d_OTCR_FileType && errors.d_OTCR_FileType.types && <span id="Err_msg">{errors.d_OTCR_FileType.types.message}</span>}
                    </FormGroup>
            
 
                <FormGroup className="Col-1-2" label="Password for Protecting the OTCR Reporting File">
                    <InputGroup id="inputOTCR_ZipPwd" name="d_OTCR_ZipPwd" defaultValue="" placeholder="OTCR ZIP FILE Password" inputRef={ register({ maxLength: 128,minLength:8}) } />
                        {errors.d_OTCR_ZipPwd && errors.d_OTCR_ZipPwd.types && <span id="Err_msg">{errors.d_OTCR_ZipPwd.types.message}</span>}
                        {errors.d_OTCR_ZipPwd && errors.d_OTCR_ZipPwd.type === "maxLength" && <span id="Err_msg">Max length is 128</span>}
                        {errors.d_OTCR_ZipPwd && errors.d_OTCR_ZipPwd.type === "minLength" && <span id="Err_msg">Min length is 8</span>}
                </FormGroup>
            </div>
            <H5 >For Import Client information file GSB/HKIDR </H5>
            <div className="Spacing-V-16"/>     
            <div className="Row"> 
            <FormGroup className="Col-1-3" label="Password for Protecting File">
                
                    <InputGroup id="inputImp_allZipPwd" name="d_imp_All_ZipPwd" defaultValue="" placeholder="Enter  Password" inputRef={ register({ maxLength: 128,minLength:4}) } />
                        {errors.d_imp_All_ZipPwd && errors.d_imp_All_ZipPwd.types && <span id="Err_msg">{errors.d_imp_All_ZipPwd.types.message}</span>}
                        {errors.d_imp_All_ZipPwd && errors.d_imp_All_ZipPwd.type === "maxLength" && <span id="Err_msg">Max length is 128</span>}
                        {errors.d_imp_All_ZipPwd && errors.d_imp_All_ZipPwd.type === "minLength" && <span id="Err_msg">Min length is 8</span>}
                </FormGroup>
            </div>
            
            {/*
            <div className="Spacing-V-16" />
            <H5 >CNBCAN</H5>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="CN BCAN Gen Type" >
                    <select  id="inputCNBcan_GenType" name="d_CNBcan_GenType" value={def_CNBcan_GenType} onChange={utils.handleStringChange(s => setdef_CNBcan_GenType(s)) }ref={register}>
                        <option value="NOT"> Not Gen </option>
                        <option value="SAMEHK"> Same as HK BCAN </option>
                        <option value="RANDOM"> Random </option>
                    </select>
                    {errors.d_CNBcan_GenType && errors.d_CNBcan_GenType.types && <span id="Err_msg">{errors.d_CNBcan_GenType.types.message}</span>}
                </FormGroup>

                <FormGroup className="Col-1-2" label="CN BCAN No Next No" labelFor="inputCNBcan_NextNo">
                    <InputGroup id="inputCNBcan_NextNo" name="d_CNBcan_NextNo" defaultValue="101" type="number" inputRef={register({min: 101, max: 9999999999})} />
                    {errors.d_CNBcan_NextNo && errors.d_CNBcan_NextNo.type === "min" && <span id="Err_msg">CN BCAN No Next No sholud be greater than 101 </span>}
                    {errors.d_CNBcan_NextNo && errors.d_CNBcan_NextNo.type === "max" && <span id="Err_msg">CN BCAN No Next No sholud be less than 9999999999 </span>}
                    {errors.d_CNBcan_NextNo && errors.d_CNBcan_NextNo.types && <span id="Err_msg">{errors.d_CNBcan_NextNo.types.message}</span>}
                </FormGroup>
               
                <FormGroup className="Col-3-2" label="CN BCAN No Range Min" labelFor="inputCNBcan_Gen_Range1">
                    <InputGroup id="inputCNBcan_Gen_Range1" name="d_CNBcan_Gen_Range1" defaultValue="101" type="number" inputRef={register({min: 0, max: 9999999999})} />
                    {errors.d_CNBcan_Gen_Range1 && errors.d_CNBcan_Gen_Range1.type === "min" && <span id="Err_msg">CN BCAN No Range Min sholud be greater than 0 </span>}
                    {errors.d_CNBcan_Gen_Range1 && errors.d_CNBcan_Gen_Range1.type === "max" && <span id="Err_msg">CN BCAN No Range Min sholud be less than 9999999999 </span>}
                    {errors.d_CNBcan_Gen_Range1 && errors.d_CNBcan_Gen_Range1.types && <span id="Err_msg">{errors.d_CNBcan_Gen_Range1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="CN BCAN No Range Max" labelFor="inputCNBcan_Gen_Range2">
                    <InputGroup id="inputCNBcan_Gen_Range2" name="d_CNBcan_Gen_Range2" defaultValue="101" type="number" inputRef={register({min: 0, max: 9999999999})} />
                    {errors.d_CNBcan_Gen_Range2 && errors.d_CNBcan_Gen_Range2.type === "min" && <span id="Err_msg">CN BCAN No Range Max sholud be greater than 0 </span>}
                    {errors.d_CNBcan_Gen_Range2 && errors.d_CNBcan_Gen_Range2.type === "max" && <span id="Err_msg">CN BCAN No Range Max sholud be less than 9999999999 </span>}
                    {errors.d_CNBcan_Gen_Range2 && errors.d_CNBcan_Gen_Range2.types && <span id="Err_msg">{errors.d_CNBcan_Gen_Range2.types.message}</span>}
                </FormGroup>
            </div>
            */}
           
            {/* <H5 >For CN RANDOM Gen BCAN No.</H5> */}
            <div className="Row">
                {/*
                <FormGroup className="Col-1-2" label="CN BCAN No Next No" labelFor="inputCNBcan_NextNo">
                    <InputGroup id="inputCNBcan_NextNo" name="d_CNBcan_NextNo" defaultValue="101" type="number" inputRef={register({min: 101, max: 9999999999})} />
                    {errors.d_CNBcan_NextNo && errors.d_CNBcan_NextNo.type === "min" && <span id="Err_msg">CN BCAN No Next No sholud be greater than 101 </span>}
                    {errors.d_CNBcan_NextNo && errors.d_CNBcan_NextNo.type === "max" && <span id="Err_msg">CN BCAN No Next No sholud be less than 9999999999 </span>}
                    {errors.d_CNBcan_NextNo && errors.d_CNBcan_NextNo.types && <span id="Err_msg">{errors.d_CNBcan_NextNo.types.message}</span>}
                </FormGroup>
               
                <FormGroup className="Col-3-2" label="CN BCAN No Range Min" labelFor="inputCNBcan_Gen_Range1">
                    <InputGroup id="inputCNBcan_Gen_Range1" name="d_CNBcan_Gen_Range1" defaultValue="101" type="number" inputRef={register({min: 0, max: 9999999999})} />
                    {errors.d_CNBcan_Gen_Range1 && errors.d_CNBcan_Gen_Range1.type === "min" && <span id="Err_msg">CN BCAN No Range Min sholud be greater than 0 </span>}
                    {errors.d_CNBcan_Gen_Range1 && errors.d_CNBcan_Gen_Range1.type === "max" && <span id="Err_msg">CN BCAN No Range Min sholud be less than 9999999999 </span>}
                    {errors.d_CNBcan_Gen_Range1 && errors.d_CNBcan_Gen_Range1.types && <span id="Err_msg">{errors.d_CNBcan_Gen_Range1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="CN BCAN No Range Max" labelFor="inputCNBcan_Gen_Range2">
                    <InputGroup id="inputCNBcan_Gen_Range2" name="d_CNBcan_Gen_Range2" defaultValue="101" type="number" inputRef={register({min: 0, max: 9999999999})} />
                    {errors.d_CNBcan_Gen_Range2 && errors.d_CNBcan_Gen_Range2.type === "min" && <span id="Err_msg">CN BCAN No Range Max sholud be greater than 0 </span>}
                    {errors.d_CNBcan_Gen_Range2 && errors.d_CNBcan_Gen_Range2.type === "max" && <span id="Err_msg">CN BCAN No Range Max sholud be less than 9999999999 </span>}
                    {errors.d_CNBcan_Gen_Range2 && errors.d_CNBcan_Gen_Range2.types && <span id="Err_msg">{errors.d_CNBcan_Gen_Range2.types.message}</span>}
                </FormGroup> */}
            </div>
                     
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Theme" labelFor="inputd_theme1">
                    <select  id="inputd_theme1" name="d_theme1" value={def_d_theme1} onChange={utils.handleStringChange(s => setdef_d_theme1(s)) }ref={register}>
                        <option value="0">0: Basic </option>
                        <option value="8">8: Dark </option>
                        <option value="1">1: Theme 1</option>
                        <option value="2">2: Theme 2</option>
                        <option value="3">3: Theme 3</option>
                        <option value="4">4: Theme 4</option>
                        <option value="5">5: Theme 5</option>
                        {/*
                        <option value="6">6: Theme 6</option>
                        <option value="7">7: Theme 7</option>
                        */}
                    </select>
                    {errors.d_theme1 && errors.d_theme1.types && <span id="Err_msg">{errors.d_theme1.types.message}</span>}
                </FormGroup>
            </div>
            {/*
            <div className="Row">
                <FormGroup className="Col-1-2" label="Enter to next input field" labelFor="inputEnter2Tab">
                <RadioGroup
                        name="d_Enter2Tab"
                        inline={true}
                        selectedValue={def_Enter2Tab}
                        onChange={utils.handleStringChange(s => setdef_Enter2Tab(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Enter2Tab && errors.d_Enter2Tab.types && <span id="Err_msg">{errors.d_Enter2Tab.types.message}</span>}
                </FormGroup>
            </div>
            */}

        
        </form>
    )
}

interface BcanConfigMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function BcanConfigMain({ gsb }: BcanConfigMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as BcanConfig[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("Detail")
    const [currentAc, setCurrentAc] = useState({} as BcanConfig)
    const [BlankAc, setBlankAc] = useState({} as BcanConfig)
    const [sbConfigRec, setsbConfigRec] = useState({} as sbConfig)

    const [currentMode, setCurrentMode] = useState("show")

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    

    
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    //BlankAc.d_p_Pref = "P"
   
 


    useEffect(() => {
        (async () => {
            try {

             
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("BcanConfigMain disp user_key: ", res.data.Flag.user_key)
                //console.log("BcanConfigMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("BcanConfigMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("BcanConfigMain disp GsbFlag: ", GsbFlag)
                //console.log("BcanConfigMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("BcanConfigMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("BcanConfigMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("BcanConfigMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("BcanConfigMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;

                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentMode])


    useEffect(() => {
        console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, GsbFlag, cardOpen01])


    useEffect(() => {

        setnPage(1)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                // usually A/E code is prefixed with C, but some user may use others e.g. Upbest uses A for A/E
                // TODO: Read A/E prefix from config
                // fixed can read true A/E
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: "",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                            }
                        },
                        cPgmId: "BcanConfig",
                        cAction: "ListRec",
                    });
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                console.log("main res.data", res.data.data)
                console.log("main currentAc", currentAc)
                setCurrentMode("show")

                setshowButton(false)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    const PrevRecProcess = async (BcanConfig: BcanConfig) => {
/*        
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "BcanConfig", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const DeleteProcess = async (BcanConfig: BcanConfig) => {
/*        
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "BcanConfig", cAction: "DelRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const NextRecProcess = async (BcanConfig: BcanConfig) => {
/*        
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "BcanConfig", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }


    const newOnSave = async (BcanConfig: BcanConfig) => {
/*        
        try {
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "BcanConfig", cAction: "AddRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const editOnSave = async (BcanConfig: BcanConfig) => {
        try {
            console.log("call editOnSave", BcanConfig)
            const res = await gsb.post('/Master', {sb_data: BcanConfig, cPgmId: "BcanConfig", cAction: "UpdateRec"})
            //showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record saved")
/*            
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
*/            
            //setCurrentAc(res.data.data)
            setCurrentAc(res.data.data[0])
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <BcanConfigView gsb={gsb} BcanConfig={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></BcanConfigView>
    switch (currentMode) {
        case "edit":
            detail = <BcanConfigEdit gsb={gsb} BcanConfig={currentAc} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></BcanConfigEdit>
            break
        case "new":
            detail = <BcanConfigEdit gsb={gsb} BcanConfig={BlankAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></BcanConfigEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>    
    
    return (
        <>
            <form >
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>BCAN Configuration</H3>
                    {/*
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    */}

                    {detail}
                </Card>
            </form>
        </>
    );
}

export default BcanConfigMain